<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { convertPrice, convertDateTime } from "@/_helpers";
import skeletonLoading from "@/components/skeleton";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, skeletonLoading },
  page: {
    title: "Pembelian",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "List Pembelian",
          href: "/pembelian",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
      isLoading: false,
      apiUrl: {
        purchase: process.env.VUE_APP_API_BASE_URL + "purchases",
        purchaseDetail: process.env.VUE_APP_API_BASE_URL + "purchase-details",
        purchaseHistoryLog:
          process.env.VUE_APP_API_BASE_URL + "purchase-history-logs",
        purchasePaymentLog:
          process.env.VUE_APP_API_BASE_URL + "purchase-payment-logs",
      },
      datas: [],
      setData: [],
      setDataPaymentLog: [],
      setDataHistoryLog: [],
      editTab: false,
      screenWidth: 0,
      sub_colors: null,
      columns: [
        {
          text: "No. Pembelian",
          value: "purchase_number",
        },
        {
          text: "Pelanggan",
        },
        {
          text: "Pembayaran",
        },
        {
          text: "Dibuat Oleh",
        },
        {
          text: "Diperbarui Oleh",
        },
        {
          text: "Status",
        },
      ],
      columnsTable: [
        {
          key: "Warna",
          label: "Warna",
          position: "text-start",
        },
        {
          key: "Ukuran",
          label: "Ukuran",
          position: "text-start",
        },
        {
          key: "previous_stock",
          label: "Stok Sebelumnya",
          position: "text-center",
        },
        {
          key: "ordered_stock",
          label: "Stok Dipesan",
          position: "text-center",
        },
        {
          key: "current_stock",
          label: "Stok Saat ini",
          position: "text-center",
        },
        {
          key: "Harga",
          label: "Harga",
          position: "text-end",
        },
        {
          key: "Total",
          label: "Total",
          position: "text-end",
        },
      ],
    };
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    this.getDetail();
    this.updateScreenWidth();
    this.onScreenResize();
    this.getPaymentLog();
    this.getHistoryLog();
  },
  methods: {
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    formatPrice(value) {
      if (value) {
        return convertPrice(value);
      }
    },
    formatDate(value) {
      if (value) {
        return convertDateTime(value);
      }
    },
    countUniqueSizes(clothColors) {
      return clothColors.reduce(
        (acc, color) => acc + color.cloth_sizes.length,
        0
      );
    },
    rowspanIndex(id) {
      let total = 0;
      const specificData = this.datas.find((item) => item.id === id);
      total = specificData.cloth_colors.reduce((sum, color) => {
        return sum + color.cloth_sizes.length;
      }, 0);
      return total;
    },
    calculateTotalQty(clothColor) {
      const totalQty = clothColor.reduce((total, item) => {
        return total + parseInt(item.qty);
      }, 0);

      return totalQty;
    },
    calculateTotalPrice(clothColor) {
      const totalPrice = clothColor.reduce((total, item) => {
        return total + parseInt(item.total);
      }, 0);

      return totalPrice;
    },
    async getDetail() {
      try {
        this.isLoading = true;
        this.$http
          .get(`${this.apiUrl.purchase}/${this.$route.params.id}`)
          .then((response) => {
            this.setData = response.data;
            // this.datas = this.addCurrentStockInClothSize(response.data);
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
        if (error.response.status == 404) {
          this.$router.push("/404-notfound");
        }
      }
    },
    async getPaymentLog() {
      try {
        // this.isLoading = true;
        this.$http
          .get(
            `${this.apiUrl.purchasePaymentLog}?purchase_id=${this.$route.params.id}`
          )
          .then((response) => {
            this.setDataPaymentLog = response.data.data;
            // this.datas = this.addCurrentStockInClothSize(response.data);
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
        if (error.response.status == 404) {
          this.$router.push("/404-notfound");
        }
      }
    },
    async getHistoryLog() {
      try {
        // this.isLoading = true;
        this.$http
          .get(
            `${this.apiUrl.purchaseHistoryLog}?purchase_id=${this.$route.params.id}`
          )
          .then((response) => {
            this.setDataHistoryLog = response.data.data;
            // console.log(this.setDataHistoryLog);
            // this.datas = this.addCurrentStockInClothSize(response.data);
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
        if (error.response.status == 404) {
          this.$router.push("/404-notfound");
        }
      }
    },
    returnBack() {
      return this.$router.go(-1);
    },
    editTable(clothSize) {
      return (clothSize.tableEdit = true);
    },
    saveEditTable(clothSize) {
      return (clothSize.tableEdit = false);
    },
    addCurrentStockInClothSize(newVal) {
      console.log(newVal);
      newVal.cloths.map((item) => {
        const newItem = { ...item };
        newItem.cloth_colors = item.cloth_colors.map((color) => {
          const newSize = { ...color };
          newSize.cloth_sizes = color.cloth_sizes.map((size) => {
            return {
              ...size,
              tableEdit: false,
              qty_received: 0,
              qty_received: 0,
            };
          });
          return newSize;
        });
        return newItem;
      });
      console.log(newVal.cloths);
      return newVal.cloths;
    },
    async acceptedPurchase() {
      console.log(
        JSON.stringify({
          purchase_details: this.setData.cloths,
          status: "accepted",
        })
      );
      try {
        this.isLoading = true;
        await this.$http
          .patch(
            `${this.apiUrl.purchase}/${this.setData.id}/approved`,
            {
              purchase_details: this.setData.cloths,
              status: "accepted",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async () => {
            this.isLoading = false;
            this.getDetail();
            this.$notify({
              message: "Status Berhasil Diubah",
              position: "bottom-right",
              type: "success",
              duration: 5000,
            });
          });
      } catch (error) {
        this.isLoading = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
        this.$notify.error({
          message: this.notification.message,
          position: "bottom-right",
          duration: 5000,
        });
      }
    },
    addDataPurchace(purchase) {
      const orderId = this.$route.params.id;
      return this.$router.push(
        `tambah?use_cloth_detail=1&purchase_id=${orderId}&supplier_id=${purchase.supplier.id}`
      );
    },
    acceptedChangeStockOrder(dataPurchace) {
      console.log(dataPurchace);
      this.$http
        .patch(
          this.apiUrl.purchaseDetail +
            "/" +
            dataPurchace.purchase_detail_id +
            "/" +
            "accepted-change-stock-order",
          {
            qty: parseInt(dataPurchace.qty),
          }
        )
        .then((resp) => {
          console.log(resp);
          this.setData.total = resp.data.total_payment;
          this.getPaymentLog();
          this.getHistoryLog();
          this.$store.dispatch("notification/success", "Data berhasil diubah");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
    },
    deletedPurchaseItem(value) {
      this.$swal
        .fire({
          title: "Apakah Kamu Yakin ?",
          text: "Data tidak dapat dikembalikan!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Ya, Hapus!",
        })
        .then((result) => {
          if (result.value) {
            let model = [];
            value.forEach((data) => {
              model.push(data.purchase_detail_id);
            });
            console.log(JSON.stringify(model));
            console.log(this.$route);
            this.$http
              .post(
                this.apiUrl.purchase +
                  "/" +
                  this.$route.params.id +
                  "/" +
                  "delete",
                {
                  purchase_ids: model,
                }
              )
              .then(() => {
                this.$store.dispatch(
                  "notification/success",
                  "Data berhasil dihapus"
                );
                this.getDetail();
              })
              .catch((error) => {
                this.isLoading = false;
                if (process.env.NODE_ENV !== "production") {
                  console.error(error);
                  if (error.response.status == 404) {
                    this.$router.push("/404-notfound");
                  }
                }
                this.$store.dispatch("notification/error", error);
              });
          }
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <div>
      <b-row v-if="!isLoading">
        <b-card>
          <div class="d-flex justify-content-end align-items-center">
            <div class="m-2">
              <b-button
                v-if="
                  setData.status && setData.status.toLowerCase() !== 'accepted'
                "
                @click="acceptedPurchase"
                variant="success fw-bold d-flex justify-content-center align-items-center gap-2"
                >Accepted <i class="bx bxs-check-circle"></i
              ></b-button>
            </div>
          </div>
          <b-card-body>
            <b-row>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">No. Pembelian</span>
                <p class="fs-16-custom">{{ setData.purchase_number }}</p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Supplier</span>
                <p class="fs-16-custom">
                  <a
                    :href="
                      '#/supplier/' + (setData.supplier && setData.supplier.id)
                    "
                    target="_blank"
                    >{{ setData.supplier && setData.supplier.name }}</a
                  >
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Tgl. Pembelian</span>
                <p class="fs-16-custom">
                  {{ setData.purchase_date }}
                </p>
              </b-col>
              <!-- <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Pembayaran</span>
                <p class="fs-16-custom">
                  {{ setData.payment_method }}
                </p>
              </b-col> -->
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Status</span>
                <span
                  class="badge-width fs-6 fw-bold text-white"
                  :class="{
                    'bg-success':
                      setData.status &&
                      setData.status.toLowerCase() === 'accepted',
                    'bg-warning':
                      setData.status &&
                      setData.status.toLowerCase() === 'ordered',
                    'bg-danger':
                      setData.status &&
                      setData.status.toLowerCase() !== 'accepted' &&
                      setData.status.toLowerCase() !== 'ordered',
                  }"
                >
                  {{ setData && setData.status }}
                </span>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Dibuat Oleh</span>
                <p class="fs-16-custom">
                  {{ setData.created_by && setData.created_by.name }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Diperbarui Oleh</span>
                <p class="fs-16-custom">
                  {{
                    setData.updated_by
                      ? setData.updated_by && setData.updated_by.name
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Tanggal Dibuat</span>
                <p class="fs-16-custom">
                  {{ formatDate(setData.created_at) }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Tanggal Diperbarui</span>
                <p class="fs-16-custom">
                  {{ formatDate(setData.updated_at) }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column my-2" md="3" sm="6">
                <span class="mb-1 fs-6 fs-16-custom">Total Pembelian</span>
                <p class="fs-16-custom">
                  {{ formatPrice(setData.total) }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-tabs pills content-class="py-0 text-muted p-0">
          <b-tab active lazy fade class="border-0 p-0">
            <template v-slot:title>
              <span class="">Detail</span>
            </template>
            <b-card>
              <b-card-body>
                <div
                  md="6"
                  class="text-end"
                  v-if="setData.status.toLowerCase() !== 'accepted'"
                >
                  <b-button @click="addDataPurchace(setData)" variant="primary"
                    ><i class="uil uil-plus"></i
                  ></b-button>
                </div>
                <div v-if="setData.cloths.length === 0">
                  Tidak ada data untuk saat ini.
                </div>
                <div v-else v-for="(cloth, indexCloth) in setData.cloths">
                  <div
                    class="my-2 d-flex justify-content-between align-items-center"
                  >
                    <p class="m-0 fs-5 fw-bold text-dark">
                      Jenis Pakaian -
                      {{
                        cloth.cloth_category ? cloth.cloth_category.name : " "
                      }}
                    </p>
                    <b-button
                      v-if="
                        indexCloth == 0 &&
                          setData.status !== 'Batal' &&
                          $store.getters['account/accountData'].role !==
                            'kasir' &&
                          setData.enable_to_edit
                      "
                      @click="addDataOrder(setData)"
                      variant="primary"
                      ><i class="uil uil-plus"></i
                    ></b-button>
                  </div>
                  <div
                    v-for="(sub_colors, key) in cloth.cloth_colors"
                    :key="key"
                  >
                    <table class="table mb-4">
                      <thead>
                        <tr class="table-head">
                          <th>Warna</th>
                          <th>SKU</th>
                          <th class="text-center">Ukuran</th>
                          <th class="text-right">Harga</th>
                          <th class="text-center">Stok Saat Ini</th>
                          <th class="text-center">Stok Dipesan</th>
                          <th class="text-center">Stok Akhir</th>
                          <th class="text-right">Total</th>
                          <th
                            v-if="setData.status.toLowerCase() !== 'accepted'"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(cloth_color, index) in sub_colors">
                          <td class="align-middle text-capitalize" width="250">
                            <div class="d-flex align-items-center">
                              <div
                                class="color-indicator"
                                :style="{
                                  background: `#${cloth_color.color.code_hexa}`,
                                  marginRight: '5px',
                                }"
                              ></div>
                              <span class="">{{ cloth_color.color.name }}</span>
                            </div>
                          </td>
                          <td width="150">
                            {{
                              cloth_color.sku_code ? cloth_color.sku_code : "-"
                            }}
                          </td>
                          <td class="text-center" width="100">
                            {{ cloth_color.cloth_sizes.size.name }}
                          </td>
                          <td class="text-right" width="150">
                            {{ formatPrice(cloth_color.price) }}
                          </td>
                          <td class="text-center" width="200">
                            {{
                              setData.status.toLowerCase() !== "accepted"
                                ? cloth_color.cloth_sizes.stock
                                : cloth_color.previous_stock
                            }}
                          </td>
                          <td class="text-center" width="300">
                            <template
                              v-if="setData.status.toLowerCase() !== 'accepted'"
                            >
                              <div class="d-flex">
                                <el-input
                                  type="number"
                                  class="custom-fielld"
                                  v-model="cloth_color.qty"
                                  :name="`input-${cloth_color.id}`"
                                  :id="`input-${cloth_color.id}`"
                                ></el-input>
                                <b-button
                                  variant="success"
                                  @click="acceptedChangeStockOrder(cloth_color)"
                                >
                                  <i class="fa fa-check"></i>
                                </b-button>
                              </div>
                            </template>
                            <span v-else>{{ cloth_color.received }}</span>
                          </td>
                          <td class="text-center" width="200">
                            {{
                              setData.status.toLowerCase() !== "accepted"
                                ? cloth_color.cloth_sizes.stock +
                                  parseInt(cloth_color.qty)
                                : cloth_color.current_stock
                            }}
                          </td>
                          <td class="text-right" width="200">
                            {{
                              formatPrice(cloth_color.price * cloth_color.qty)
                            }}
                          </td>
                          <td
                            width="100"
                            v-if="
                              index === 0 &&
                                setData.status.toLowerCase() !== 'accepted'
                            "
                            :rowspan="sub_colors.length"
                            class="text-center"
                          >
                            <b-button
                              @click="deletedPurchaseItem(sub_colors)"
                              variant="danger"
                            >
                              <i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                        <tr>
                          <th colspan="5" class="text-end font-bold">Total</th>
                          <th class="text-center">
                            {{ calculateTotalQty(sub_colors) }} PCS
                          </th>
                          <th></th>
                          <th class="text-end">
                            {{ formatPrice(calculateTotalPrice(sub_colors)) }}
                          </th>
                        </tr>
                      </tbody>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              Riwayat Pembayaran
            </template>
            <b-card>
              <b-card-body>
                <table class="table table-bordered mt-3">
                  <thead>
                    <tr class="table-head">
                      <th class="text-center">Tanggal</th>
                      <th class="text-center">Dibuat Oleh</th>
                      <th class="text-left w-100">Catatan</th>
                      <th class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="paymentLog in setDataPaymentLog"
                      :key="paymentLog.id"
                    >
                      <td class="text-center">
                        {{ formatDate(paymentLog.created_at) }}
                      </td>
                      <td class="text-center">
                        <a
                          :href="'#/pengguna/' + paymentLog.created_by.id"
                          target="_blank"
                          >{{ paymentLog.created_by.name }}</a
                        >
                      </td>
                      <td class="text-left">{{ paymentLog.note }}</td>
                      <td class="text-right">
                        {{ formatPrice(paymentLog.total) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              History
            </template>
            <b-card>
              <b-card-body>
                <table class="table table-bordered mt-3">
                  <thead>
                    <tr class="table-head">
                      <th class="text-center">Tanggal</th>
                      <th class="text-center">Dibuat Oleh</th>
                      <th class="text-left w-100">Catatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="orderLog in setDataHistoryLog"
                      :key="orderLog.id"
                    >
                      <td class="text-center">
                        {{ formatDate(orderLog.created_at) }}
                      </td>
                      <td class="text-center">
                        <a
                          :href="'#/pengguna/' + orderLog.created_by.id"
                          target="_blank"
                          >{{ orderLog.created_by.name }}</a
                        >
                      </td>
                      <td class="text-left">{{ orderLog.note }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-row>
      <b-row v-else>
        <b-card>
          <div class="d-flex justify-content-end align-items-center">
            <div class="m-2">
              <skeletonLoading width="110px" height="35px" borderRadius="7px" />
            </div>
            <div class="m-2">
              <skeletonLoading width="90px" height="35px" borderRadius="7px" />
            </div>
          </div>
          <b-card-body>
            <b-row>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="2">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col>
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="80"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
              <b-col class="d-flex flex-column" sm="3" lg="3">
                <skeletonLoading width="60%" height="25px" borderRadius="4px" />
                <skeletonLoading
                  width="100%"
                  height="30px"
                  class="mt-2"
                  borderRadius="4px"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-body>
            <skeletonLoading
              width="15%"
              height="30px"
              class="mt-2"
              borderRadius="4px"
            />
            <table
              class="table mt-2"
              v-for="cloth in setData.cloths"
              :key="cloth.id"
            >
              <skeletonLoading
                width="100%"
                height="250px"
                class="mt-2"
                borderRadius="4px"
              />
            </table>
          </b-card-body>
          <b-card-body>
            <skeletonLoading
              width="15%"
              height="30px"
              class="mt-2"
              borderRadius="4px"
            />
            <table
              class="table mt-2"
              v-for="cloth in setData.cloths"
              :key="cloth.id"
            >
              <skeletonLoading
                width="100%"
                height="200px"
                class="mt-2"
                borderRadius="4px"
              />
            </table>
          </b-card-body>
          <b-card-body>
            <skeletonLoading
              width="15%"
              height="30px"
              class="mt-2"
              borderRadius="4px"
            />
            <table
              class="table mt-2"
              v-for="cloth in setData.cloths"
              :key="cloth.id"
            >
              <skeletonLoading
                width="100%"
                height="200px"
                class="mt-2"
                borderRadius="4px"
              />
            </table>
          </b-card-body>
        </b-card>
      </b-row>
    </div>
  </Layout>
</template>

<style scoped>
.vertical-align {
  vertical-align: middle;
}
.table-bordered {
  border-color: #ebeef5 !important;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.table-head {
  background-color: #f5f7fa;
  height: 60px;
  vertical-align: middle;
  color: #2f2f2f;
  font-weight: 900;
  width: 500px;
}

.activity-feed .feed-item:last-child {
  border-color: #ebeef5 !important;
}
.badge-width {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
}

.table th,
.table tr,
.table td {
  border: 1px solid #c0c4cc !important;
  vertical-align: middle !important;
}
</style>
